// services.ts
import axios from 'axios';

import { Demandes } from './models/Demandes';
import { Voyageurs } from './models/Voyageurs';


//const BASE_URL = ' http://localhost:3002'; // Ajustez cela en fonction de l'URL de votre serveur backend
const BASE_URL = ' https://nodejs-admin.visamonde.com'; // Ajustez cela en fonction de l'URL de votre serveur backend


export const majDemande = async (chemin: string, data: { datevalidite: string, dateexpiration: string, infosvisa: string }) => {
  try {
    const response = await axios.put(`${BASE_URL}/majdemande/${chemin}`, data);
    return response.data; // Retourne la réponse du serveur après la mise à jour
  } catch (error) {
    console.error('Erreur lors de la mise à jour de la demande:', error);
    throw error; // Lance l'erreur pour une meilleure gestion
  }
};


// Services.ts
export const uploadVisa = async (dossier: any, formData: any) => {
  // Remplacez l'URL par celle de votre API
  const url = `${BASE_URL}/voyageurs/${dossier}/uploadVisa`;
  const response = await fetch(url, {
    method: 'POST',
    body: formData, // Envoie le FormData contenant le fichier
  });

  if (!response.ok) {
    throw new Error('Erreur lors de l\'upload du visa');
  }

  return await response.json(); // Renvoie la réponse du serveur
};

// Dans vos services
export const recupereUtilisateurs = async () => {
  try {
    const response = await fetch(`${BASE_URL}/utilisateurs`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Erreur lors de la récupération des utilisateurs:', error);
    throw error; // Renvoyer l'erreur pour une meilleure gestion
  }
};



export const recupererPasseport = async (cheminVoyageur: string): Promise<string> => {
  try {
    // Assurez-vous que l'URL et le paramètre sont correctement configurés pour votre backend
    const response = await axios.get(`${BASE_URL}/voyageurs/passeport`, { params: { chemin: cheminVoyageur } });
    return response.data.urlPasseport; // Assurez-vous que le backend renvoie un objet avec la clé 'urlPasseport'
  } catch (error) {
    console.error("Erreur lors de la récupération de l'URL du passeport", error);
    throw error;
  }
};

// Ajoutez cette nouvelle fonction pour récupérer les pièces jointes
export const recupererPiecesJointes = async (chemin: string) => {
  try {
    const reponse = await axios.get(`${BASE_URL}/piecesJointes/${chemin}`);
    return reponse.data;
  } catch (erreur) {
    console.error("Erreur lors de la récupération des pièces jointes", erreur);
    throw erreur;
  }
};

export const recupererContenuFormulaire = async (chemin: string) => {
  try {
    const reponse = await axios.get(`${BASE_URL}/formulaire/${chemin}`);
    return reponse.data; // Supposons que le backend renvoie directement le contenu JSON
  } catch (erreur) {
    console.error("Erreur lors de la récupération du contenu du formulaire", erreur);
    throw erreur;
  }
};


// Fonction pour récupérer la liste des demandes
export const recupereListeDemandes = async (): Promise<Demandes[]> => {
    try {
      const response = await axios.get<Demandes[]>(`${BASE_URL}/demandes`);
      return response.data;
    } catch (error) {
      console.error('Erreur lors de la récupération des demandes:', error);
      throw error;
    }
  };

  export const recupererInfoVoyageur = async (idVoyageur: number): Promise<Voyageurs> => {
    try {
      const response = await axios.get(`${BASE_URL}/voyageurs/${idVoyageur}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  export const modifierInfoVoyageur = async (voyageur: Voyageurs): Promise<void> => {
    try {
      await axios.put(`${BASE_URL}/voyageurs/${voyageur.id}`, voyageur);
    } catch (error) {
      throw error;
    }
  };

  // Fonction pour récupérer les bons de commande
export const recupereBDC = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/bdc`);
    return response.data; // Supposons que le serveur renvoie directement la liste des BDC
  } catch (error) {
    throw error;
  }
};

export const recupererDemandesBDC = async (idBonDeCommande:any) => {
  // Remplacez cette URL par l'URL de votre API
  const response = await fetch(`${BASE_URL}/bdc/${idBonDeCommande}/demandes`);
  if (!response.ok) {
    throw new Error('Erreur lors de la récupération des demandes pour le bon de commande');
  }
  return await response.json();
};



  