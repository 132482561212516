import React, { useState, useEffect } from 'react';
import { Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, useMediaQuery, useTheme, FormControl, InputLabel, Select, MenuItem, DialogContentText, IconButton, Paper } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Home from './Home';
import { SelectChangeEvent } from '@mui/material/Select';


interface Visa {
  id: string;
  id_pays: string;
  libelle_pays: string;
  titre: string;
  categorie: string;
  prixconsulat: string;
  prixintervention: string;
  url: string;
  informations: string;
}

interface Pays {
  id: string;
  libelle: string;
}

const VisasCrud = () => {
  const theme = useTheme();
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const [visas, setVisas] = useState<Visa[]>([]);
  const [open, setOpen] = useState(false);
  const [currentVisa, setCurrentVisa] = useState({ id: '', id_pays: '', titre: '', libelle_pays: '', prixconsulat: '', prixintervention: '', url: '', informations: '' });
  const [isEdit, setIsEdit] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [pays, setPays] = useState<Pays[]>([]); // Ajout de l'état pour les pays


  useEffect(() => {
    fetchPays();
    fetchVisas();
  }, []);

  const fetchVisas = async () => {
    const response = await fetch('https://nodejs-admin.visamonde.com/visas');
    const data = await response.json();
    setVisas(data);
  };

  const fetchPays = async () => {
    const response = await fetch('https://nodejs-admin.visamonde.com/pays');
    const data = await response.json();
    setPays(data);
  };

  const handleOpen = (visa: Visa | null = null) => {
    if (visa) {
      setCurrentVisa(visa);
      setIsEdit(true);
    } else {
      setCurrentVisa({ id: '', id_pays: '', titre: '', libelle_pays: '', prixconsulat: '', prixintervention: '', url: '', informations: '' });
      setIsEdit(false);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent
  ) => {
    const name = event.target.name;
    let value: any = event.target.value;
  
    setCurrentVisa(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };
  

  const handleSubmit = async () => {
    const method = isEdit ? 'PUT' : 'POST';
    const endpoint = isEdit ? `https://nodejs-admin.visamonde.com/visas/${currentVisa.id}` : 'https://nodejs-admin.visamonde.com/visas';

    await fetch(endpoint, {
      method,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(currentVisa),
    });

    fetchVisas();
    handleClose();
  };

  const handleOpenConfirm = (id: string) => {
    setOpenConfirm(true);
    setDeleteId(id);
  };

  const handleDeleteConfirmed = async () => {
    if (deleteId) {
      await fetch(`https://nodejs-admin.visamonde.com/visas/${deleteId}`, {
        method: 'DELETE',
      });
  
      fetchVisas();
      setDeleteId(null);
      setOpenConfirm(false);
    }
  };

  // Le reste du composant reste similaire à PaysCrud avec les ajustements nécessaires pour gérer les champs spécifiques de visas
  // Ajoutez ici les éléments JSX pour afficher, ajouter, modifier et supprimer des visas
  return (
    <>
      <Home />
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleOpen()}
        sx={{ m: 2 }}
      >
        Ajouter un visa
      </Button>
      <Paper sx={{ margin: 2, padding: 2 }}>
        <Grid container spacing={2}>
          {/* Entêtes */}
          <Grid container spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={12} sm={3}><b>Titre</b></Grid>
            <Grid item xs={6} sm={2}><b>Pays</b></Grid>
            <Grid item xs={6} sm={2}><b>Prix Consulat</b></Grid>
            <Grid item xs={6} sm={2}><b>Prix Intervention</b></Grid>
            <Grid item xs={6} sm={2}><b>Informations</b></Grid>
            <Grid item xs={12} sm={1}><b>Actions</b></Grid>
          </Grid>
          {/* Données */}
          {visas.map((visa, index) => (
            <React.Fragment key={visa.id}>
              {index > 0 && <Grid item xs={12} sx={{ borderBottom: 1, borderColor: 'divider' }} />}
              <Grid container spacing={2} sx={{ '&:hover': { backgroundColor: 'action.hover' }, padding: 1 }}>
                <Grid item xs={12} sm={3}>{visa.titre}</Grid>
                <Grid item xs={6} sm={2}>{visa.libelle_pays}</Grid>
                <Grid item xs={6} sm={2}>{visa.prixconsulat}</Grid>
                <Grid item xs={6} sm={2}>{visa.prixintervention}</Grid>
                <Grid item xs={6} sm={2}>{visa.informations}</Grid>
                <Grid item xs={12} sm={1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <IconButton onClick={() => handleOpen(visa)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleOpenConfirm(visa.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Paper>
  
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>{isEdit ? 'Modifier le visa' : 'Ajouter un visa'}</DialogTitle>
        <DialogContent>
          {/* Formulaire pour ajouter/modifier les visas */}
          <TextField autoFocus margin="dense" id="titre" name="titre" label="Titre" type="text" fullWidth variant="outlined" value={currentVisa.titre} onChange={handleChange} sx={{ mb: 2 }} />
          <FormControl fullWidth margin="dense" variant="outlined" sx={{ mb: 2 }}>
            <InputLabel id="id_pays-label">Pays</InputLabel>
            <Select
              labelId="id_pays-label"
              id="id_pays"
              name="id_pays"
              value={currentVisa.id_pays}
              onChange={handleChange}
              label="Pays"
            >
              {pays.map((pays) => (
                <MenuItem key={pays.id} value={pays.id}>{pays.libelle}</MenuItem>
              ))}
            </Select>
          </FormControl>          <TextField margin="dense" id="prixconsulat" name="prixconsulat" label="Prix Consulat" type="number" fullWidth variant="outlined" value={currentVisa.prixconsulat} onChange={handleChange} sx={{ mb: 2 }} />
          <TextField margin="dense" id="prixintervention" name="prixintervention" label="Prix Intervention" type="number" fullWidth variant="outlined" value={currentVisa.prixintervention} onChange={handleChange} sx={{ mb: 2 }} />
          <TextField margin="dense" id="informations" name="informations" label="Informations" type="text" fullWidth variant="outlined" value={currentVisa.informations} onChange={handleChange} sx={{ mb: 2 }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={handleSubmit}>{isEdit ? 'Modifier' : 'Ajouter'}</Button>
        </DialogActions>
      </Dialog>
  
      <Dialog open={openConfirm} onClose={() => setOpenConfirm(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Confirmez la suppression"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Êtes-vous sûr de vouloir supprimer ce visa ?</DialogContentText>
          </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenConfirm(false)} color="primary">
          Annuler
        </Button>
        <Button onClick={handleDeleteConfirmed} color="primary" autoFocus>
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  </>
);

};

export default VisasCrud;
