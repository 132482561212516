import React, { useEffect, useRef, useState } from 'react';
import { TextField, Button, Avatar, Box, Link, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { recupererInfoVoyageur, modifierInfoVoyageur, recupererPasseport, uploadVisa, majDemande } from '../Services';
import { Voyageurs } from '../models/Voyageurs';

const InfoVoyageur: React.FC<{ idVoyageur: number; chemin: string; onValider: () => void }> = ({ idVoyageur, chemin, onValider }) => {
  const [voyageur, setVoyageur] = useState<Voyageurs | null>(null);
  const [urlPasseport, setUrlPasseport] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [dateValidite, setDateValidite] = useState('');
  const [dateExpiration, setDateExpiration] = useState('');
  const [infosVisa, setInfosVisa] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const fetchVoyageur = async () => {
      try {
        const infoVoyageur = await recupererInfoVoyageur(idVoyageur);
        setVoyageur(infoVoyageur);
      } catch (error) {
        console.error('Erreur lors de la récupération des informations du voyageur:', error);
      }
    };

    if (idVoyageur) {
      fetchVoyageur();
    }
  }, [idVoyageur]);

  useEffect(() => {
    const fetchPasseport = async () => {
      if (voyageur && voyageur.dossier) {
        try {
          const url = await recupererPasseport(voyageur.dossier);
          setUrlPasseport(url);
        } catch (error) {
          console.error('Erreur lors de la récupération de l\'URL du passeport:', error);
        }
      }
    };

    fetchPasseport();
  }, [voyageur]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setVoyageur((prev) => ({
      ...prev!,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (!voyageur) return;
    try {
      console.log(voyageur);
      await modifierInfoVoyageur(voyageur);
      alert("Informations mises à jour avec succès !");
      onValider();
    } catch (error) {
      console.error('Erreur lors de la mise à jour des informations du voyageur:', error);
    }
  };

  const handleUploadClick = () => {
    setOpenModal(true);
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (!file || !voyageur) {
      return;
    }

    const formData = new FormData();
    formData.append("visa", file);

    try {
      await uploadVisa(chemin, formData);
      await majDemande(chemin, { datevalidite: dateValidite, dateexpiration: dateExpiration, infosvisa: infosVisa });
      alert('Le visa a été uploadé avec succès.');
      onValider();
    } catch (error) {
      console.error('Erreur lors de l\'upload du visa:', error);
      alert('Erreur lors de l\'upload du visa.');
    }
  };

  const handleModalSubmit = () => {
    setOpenModal(false);
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const selectText = (e: any) => {
    if (e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement) {
      e.target.select();
    }
  };

  if (!voyageur) return <p>Chargement des informations...</p>;

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={2} p={2}>
      <Avatar 
        src={voyageur.url} 
        alt="Avatar" 
        sx={{ width: 100, height: 100 }} 
        onClick={() => window.open(voyageur.url, '_blank')}
        style={{ cursor: 'pointer' }}
      />
      {urlPasseport && (
        <Link href={urlPasseport} target="_blank" rel="noopener" sx={{ mt: 2 }}>
          Voir le passeport
        </Link>
      )}
      <TextField label="Nom" variant="outlined" name="nompax" fullWidth value={voyageur.nompax} onChange={handleChange} onMouseOver={selectText} />
      <TextField label="Prénom" variant="outlined" name="prenompax" fullWidth value={voyageur.prenompax} onChange={handleChange} onMouseOver={selectText} />
      <TextField label="Numéro passeport" variant="outlined" name="numeropax" fullWidth value={voyageur.numeropax} onChange={handleChange} onMouseOver={selectText} />
      <TextField label="Date de délivrance" variant="outlined" name="datedelivrance" fullWidth value={voyageur.datedelivrance} onChange={handleChange} onMouseOver={selectText} />
      <TextField label="Date d'expiration" variant="outlined" name="dateexpiration" fullWidth value={voyageur.dateexpiration} onChange={handleChange} onMouseOver={selectText} />

      <TextField label="Adresse" variant="outlined" name="adresse" fullWidth value={voyageur.adresse} onChange={handleChange} onMouseOver={selectText} />
      <TextField label="Code Postal" variant="outlined" name="cp" fullWidth value={voyageur.cp} onChange={handleChange} onMouseOver={selectText} />
      <TextField label="Ville" variant="outlined" name="ville" fullWidth value={voyageur.ville} onChange={handleChange} onMouseOver={selectText} />
      <TextField label="Profession" variant="outlined" name="profession" fullWidth value={voyageur.profession} onChange={handleChange} onMouseOver={selectText} />
      <TextField label="Téléphone" variant="outlined" name="telephone" fullWidth value={voyageur.telephone} onChange={handleChange} onMouseOver={selectText} />

      <Button onClick={handleSubmit} variant="contained" color="primary" sx={{ mt: 2 }}>Valider</Button>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
        accept=".pdf"
      />
      <Button variant="contained" onClick={handleUploadClick} sx={{ mt: 2 }}>
        Uploader Visa
      </Button>

      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle>Informations Visa</DialogTitle>
        <DialogContent>
          <TextField
            label="Date de validité"
            type="date"
            fullWidth
            value={dateValidite}
            onChange={(e) => setDateValidite(e.target.value)}
            sx={{ mt: 2 }}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Date d'expiration"
            type="date"
            fullWidth
            value={dateExpiration}
            onChange={(e) => setDateExpiration(e.target.value)}
            sx={{ mt: 2 }}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Informations Visa"
            variant="outlined"
            fullWidth
            value={infosVisa}
            onChange={(e) => setInfosVisa(e.target.value)}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)}>Annuler</Button>
          <Button onClick={handleModalSubmit} variant="contained" color="primary">Valider</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default InfoVoyageur;
