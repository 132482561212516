import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { recupereUtilisateurs } from '../Services'; // Assurez-vous que cette fonction est correctement implémentée pour récupérer les utilisateurs
import { Utilisateur } from '../models/Utilisateur';
import Home from './Home';

const UtilisateursScreen = () => {
    const [utilisateurs, setUtilisateurs] = useState<Utilisateur[]>([]); // Utilisez le type Utilisateur pour l'état

    const fetchUtilisateurs = async () => {
        try {
            const users = await recupereUtilisateurs();
            setUtilisateurs(users);
        } catch (error) {
            console.error('Erreur lors de la récupération des utilisateurs:', error);
        }
    };

    useEffect(() => {
        fetchUtilisateurs();
    }, []);

    return (
        <>
            <Home />
            <Typography variant="h6" style={{ margin: '20px 0', marginLeft:'15px' }}>
                Nombre de Clients: {utilisateurs.length}
            </Typography>
            <TableContainer component={Paper} style={{ maxHeight: '800px' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Nom</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Date d'inscription</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {utilisateurs.map((user) => (
                            <TableRow key={user.id_utilisateur}>
                                <TableCell>{user.id_utilisateur}</TableCell>
                                <TableCell>{user.nom}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{new Date(user.date_inscription).toLocaleDateString()}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default UtilisateursScreen;
